



















import { getMyRecharges } from '@/api/recharges';
import { GetResponseErrors } from '@/common/utils';
import TheSnackbar from '@/components/TheSnackbar.vue';
import { Operator } from '@/models/interfaces/Operator';
import { Recharge } from '@/models/interfaces/Recharge'
import Vue from 'vue'
import RechargeCard from './components/RechargeCard.vue';
import InfiniteLoading from 'vue-infinite-loading';

export default Vue.extend({
  components: { RechargeCard, TheSnackbar, InfiniteLoading },
  name: 'sales-report',
  data() {
    return {
      recharges: [] as Recharge[],
      filters: {
        'operator_id': [] as Operator[],
        currentPage: 1
      },
      totalAvailable: 0,
    }
  },
  created() {

    if (this.$route.params.page) {
        const param = parseInt(this.$route.params.page);
        this.filters.currentPage = param > 0 ? param : 1;
    }

    // this.loadRecharges(
    //   this.filters.currentPage
    // );
  },
  methods: {
    async loadRecharges(page: number) {
      try {
        const response = await getMyRecharges(page)
        this.recharges = response.data.data;
        // console.log(response.data);
      } catch (err) {
        (this.$refs.snackbar as any).show(GetResponseErrors(err))
      }
    },
    async infiniteHandler($state: any) {
      try {
        const response = await getMyRecharges(this.filters.currentPage)
        if (response.data.data.length > 0) {
          this.recharges.push(...response.data.data);
          this.totalAvailable = response.data.total;
          if (this.filters.currentPage < response.data.last_page) {
            this.filters.currentPage += 1;
            $state.loaded();

          } else {
            $state.complete();
          }
        } else {
          $state.complete();
        }
        // console.log(response.data);
      } catch (err) {
        (this.$refs.snackbar as any).show(GetResponseErrors(err))
      }
    },
  }
})
